import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DepartmentState, departmentAdapter } from './department.state';
import { Department } from '@models';

export const DEPARTMENT_STATE_NAME = 'department';
const selectDepartmentState = createFeatureSelector<DepartmentState>(
  DEPARTMENT_STATE_NAME
);
export const departmentSelector = departmentAdapter.getSelectors();
const selectDepartments = createSelector(
  selectDepartmentState,
  departmentSelector.selectAll
);
const selectDepartmentEntities = createSelector(
  selectDepartmentState,
  departmentSelector.selectEntities
);
const selectDepartmentById = (id: Department['id']) =>
  createSelector(selectDepartmentEntities, departments =>
    departments ? departments[id] : null
  );
const selectDepartmentIsLoading = createSelector(
  selectDepartmentState,
  state => state.isLoading
);
const selectDissociateMember = createSelector(
  selectDepartmentState,
  state => state.dissociateUser
);

export default {
  selectDepartmentById,
  selectDepartmentIsLoading,
  selectDepartmentEntities,
  selectDepartmentState,
  selectDepartments,
  selectDissociateMember,
};
