import { ApiResponse, Department, User } from '@models';
import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const departmentActions = createActionGroup({
  source: 'department',
  events: {
    fetch: emptyProps(),
    'fetch success': props<{ data: ApiResponse<Department[]> }>(),
    delete: props<{ id: Department['id'] }>(),
    'delete success': props<{ id: Department['id'] }>(),
    update: props<{
      data: Department;
      id: Department['id'];
      message?: string;
      ref?: string;
    }>(),
    'update success': props<{ data: Update<Department> }>(),
    add: props<{ data: Department; ref?: string }>(),
    'add success': props<{ data: Department }>(),
    'update loader': props<{ isLoading: boolean }>(),
    'add member': props<{
      data: { id: Department['id']; userId: string };
    }>(),
    'add member success': props<{ data: Department['members'][0] }>(),
    'delete member': props<{ id: string; departmentId: string; user?: User }>(),
    'delete member success': props<{
      id: string;
      departmentId: string;
      user?: User;
    }>(),
    'fetch dissociate user': emptyProps(),
    'fetch dissociate user success': props<{ data: User[] }>(),
  },
});
export default departmentActions;
