// creating the entity adapter
import { Department, User } from '@models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
export interface DepartmentState extends EntityState<Department> {
  isLoading: boolean;
  dissociateUser: User[];
}
export const departmentAdapter = createEntityAdapter<Department>();
export const departmentInitialState: DepartmentState =
  departmentAdapter.getInitialState({
    isLoading: false,
    dissociateUser: [],
  });
